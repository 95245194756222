import React, { useState, useEffect } from "react"
import { Link, useParams, useLocation, useNavigate } from "react-router-dom"
import {
  Form,
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Nav,
  NavItem,
  NavLink,
  CardText,
  TabContent,
  TabPane,
  CardHeader,
  Label,
  Input,
  Button,
} from "reactstrap"

// Form Editor
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import classnames from "classnames"
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import ConverterSoal from "components/ConverterSoal/ConverterSoal"
import Swal from "sweetalert2"
import SessionTimer from "components/CountdownTimer/SessionTimerPreview"
import CheckStatus from "components/CheckStatus/CheckStatus"
import SplitPane, { Pane } from "split-pane-react"
import SoalText from "components/RefrensiJawaban/SoalText"

const FormEditors = () => {
  //meta title
  document.title = "Soal Simulasi"
  const { idSimulasi } = useParams()
  const { idSimulasi2 } = useParams()
  const [activeTab1, setactiveTab1] = useState("5")
  const [activeCard, setactiveCard] = useState("7")
  const [activeCardEditor, setactiveCardEditor] = useState("5")
  const [isHidden, setIsHidden] = useState(false)
  const [isHiddenEditor, setIsHiddenEditor] = useState(false)
  const [rows1, setrows1] = useState([{ id: 1 }])
  const [juf, setJuf] = useState([])
  const [data, setData] = useState([])
  const [listFile, setListfile] = useState()
  const userData = JSON.parse(localStorage.getItem("authUser"))
  const [loading, setLoading] = useState(true)
  const [loadingFile, setLoadingFile] = useState(false)
  const [updateFIle, setUpdateFile] = useState()
  const [defaultHeight, setDefaultHight] = useState("400px")
  const [lastUpdate, setLastUpdate] = useState()
  const [expandStatus, setExpandStatus] = useState(false)
  const [autoSave, setAutoSave] = useState(false)

  const [sizes, setSizes] = useState(["100%", "100%"])

  const location = useLocation()
  const navigate = useNavigate()

  const layoutCSS = {
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  }

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  function expand() {
    if (expandStatus == false) {
      setSizes(["100%", 0])
      setExpandStatus(true)
      setDefaultHight("100vh")
    } else {
      setSizes(["100%", "100%"])
      setExpandStatus(false)
      setDefaultHight("400px")
    }
  }

  function expandEditor() {
    if (expandStatus == false) {
      setSizes([0, "100%"])
      setExpandStatus(true)
      setDefaultHight("100vh")
    } else {
      setSizes(["100%", "100%"])
      setExpandStatus(false)
      setDefaultHight("400px")
    }
  }

  useEffect(() => {
    // CheckStatus(idSimulasi2, idSimulasi)
    setLoading(true)
    const baseURL =
      process.env.REACT_APP_BACKEND_URL_VERSION +
      "Soal-simulasi-preview?id_simulasi=" +
      idSimulasi +
      "&id_asesi=" +
      idSimulasi2
    Swal.fire({
      title: "Loading",
      text: "Please wait while we fetch the data...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading()
      },
    })
    fetch(baseURL, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token_2"),
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.meta.code === 200) {
          setData(responseData.data[0].data)
          setLoading(false)
          Swal.close()
        } else {
          setLoading(false)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: responseData.meta.message,
          }).then(() => {
            // window.location.href = `http://utt-bpkp.alfahuma.com/dashboard`
          })
        }
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong! Please try again later.",
        }).then(() => {
          // window.location.href = `http://utt-bpkp.alfahuma.com/dashboard`
        })
      })
  }, [])

  useEffect(() => {
    if (data && data[0]) {
      const baseURLGet =
        process.env.REACT_APP_BACKEND_URL_VERSION +
        `monitoring/log-last?id_peserta=` +
        userData.id_peserta +
        `&id_simulasi=` +
        idSimulasi +
        `&id_jadwal=` +
        data[0].mjs_id_mjadwal

      fetch(baseURLGet, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token_2"),
          "Content-Type": "application/json",
        },
      })
        .then(response => response.json())
        .then(responseData => {
          // console.log(responseData)
          if (responseData.meta.code === 200) {
            if (responseData.data[0].value) {
              setLastUpdate(responseData.data[0].updated_at)
            }
          }
        })
        .catch(error => {
          console.log(error)
        })
    }
  }, [data])

  const handleRemoveRow = id => {
    const filteredRows = rows1.filter(row => row.id !== id)
    setrows1(filteredRows)

    const jufIndex = juf.findIndex(item => item.index === id)
    if (jufIndex > -1) {
      const id_juf = juf[jufIndex].id_juf

      // const myHeaders = new Headers()
      // myHeaders.append(
      //   "Authorization",
      //   "Bearer " + localStorage.getItem("access_token_2")
      // )

      // const requestOptions = {
      //   method: "DELETE",
      //   headers: myHeaders,
      //   redirect: "follow",
      // }
      // const baseURL =
      //   process.env.REACT_APP_BACKEND_URL_VERSION +
      //   `jawaban/jawaban-file?id_juf=${id_juf}`
      // fetch(baseURL, requestOptions)
      //   .then(response => response.text())
      //   .then(result => {
      //     setUpdateFile(updateFIle + "1")
      //   })
      //   .catch(error => console.log("error", error))
    }
  }

  function handleAddRowNested() {
    const modifiedRows = [...rows1]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setrows1(modifiedRows)
  }

  const [file, setFile] = useState(null)

  const handleFileChange = (id, e) => {
    setLoadingFile(true)
    setFile(e.target.files[0])
    const userData = JSON.parse(localStorage.getItem("authUser"))

    // const myHeaders = new Headers()
    // myHeaders.append(
    //   "Authorization",
    //   "Bearer " + localStorage.getItem("access_token_2")
    // )

    // const formdata = new FormData()
    // formdata.append("id_simulasi", parseInt(idSimulasi))
    // formdata.append("id_peserta", userData.id_peserta)
    // formdata.append("id_jadwal", data[0].mjs_id_mjadwal)
    // formdata.append("file", e.target.files[0], e.target.files[0].name)
    // formdata.append("file_name", e.target.files[0].name)

    // const requestOptions = {
    //   method: "POST",
    //   headers: myHeaders,
    //   body: formdata,
    //   redirect: "follow",
    // }

    // const baseURL =
    //   process.env.REACT_APP_BACKEND_URL_VERSION + `jawaban/jawaban-file`
    // fetch(baseURL, requestOptions)
    //   .then(response => response.json())
    //   .then(responseData => {
    //     if (responseData.meta.code === 200) {
    //       const jufIndex = juf.findIndex(item => item.index === id)
    //       if (jufIndex > -1) {
    //         const jufId = juf[jufIndex].id_juf
    //         handleRemoveRow(jufId)
    //         const updatedJuf = [...juf]
    //         updatedJuf[jufIndex] = {
    //           index: id,
    //           id_juf: responseData.data[0].id_jawaban_upload_file,
    //         }
    //         setJuf(updatedJuf)
    //         setUpdateFile(updateFIle + "1")
    //       } else {
    //         setJuf([
    //           ...juf,
    //           {
    //             index: id,
    //             id_juf: responseData.data[0].id_jawaban_upload_file,
    //           },
    //         ])
    //         setUpdateFile(updateFIle + "1")
    //       }
    //       setLoadingFile(false)
    //     }
    //   })
    //   .catch(error => console.log("error", error))
    setAutoSave(true)
  }

  // useEffect(() => {
  //   if (data && data[0]) {
  //     var myHeaders = new Headers()
  //     myHeaders.append(
  //       "Authorization",
  //       "Bearer " + localStorage.getItem("access_token_2")
  //     )

  //     var formdata = new FormData()

  //     var requestOptions = {
  //       method: "GET",
  //       headers: myHeaders,
  //       redirect: "follow",
  //     }

  //     const baseUrlList =
  //       process.env.REACT_APP_BACKEND_URL_VERSION +
  //       "jawaban/jawaban-file?id_peserta=" +
  //       userData.id_peserta +
  //       "&id_simulasi=" +
  //       idSimulasi +
  //       `&id_jadwal=` +
  //       data[0].mjs_id_mjadwal

  //     fetch(baseUrlList, requestOptions)
  //       .then(response => response.json())
  //       .then(result => {
  //         if (result.meta.code === 200) {
  //           setListfile(result.data)
  //         }
  //       })
  //       .catch(error => console.log("error", error))
  //   }
  // }, [data, updateFIle])

  useEffect(() => {
    const handleWheelEvent = event => {
      if (event.ctrlKey) {
        event.preventDefault()
      }
    }

    window.addEventListener("wheel", handleWheelEvent, { passive: false })

    return () => {
      window.removeEventListener("wheel", handleWheelEvent)
    }
  }, [])

  const handleSubmit = () => {
    Swal.fire({
      title: "KONFIRMASI",
      text: "Anda yakin untuk menyelesaikan soal ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Loading",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading()
          },
        }) // Display loading state
        window.history.back()
        // const baseURLGet =
        //   process.env.REACT_APP_BACKEND_URL_VERSION +
        //   `jawaban/jawaban-peserta?id_peserta=` +
        //   userData.id_peserta +
        //   `&id_simulasi=` +
        //   idSimulasi +
        //   `&id_jadwal=` +
        //   data[0].mjs_id_mjadwal

        // fetch(baseURLGet, {
        //   method: "GET",
        //   headers: {
        //     Authorization: "Bearer " + localStorage.getItem("access_token_2"),
        //     "Content-Type": "application/json",
        //   },
        // })
        //   .then(response => response.json())
        //   .then(responseData => {
        //     if (responseData.meta.code === 200) {
        //       const myHeaders = new Headers();
        //       myHeaders.append("Content-Type", "application/json");
        //       myHeaders.append("Authorization", "Bearer " + localStorage.getItem("access_token_2"));

        //       const raw = JSON.stringify({
        //         "id_peserta": userData.id_peserta,
        //         "jawaban": JSON.stringify(juf),
        //         "id_simulasi": idSimulasi,
        //         "id_jadwal": data[0].mjs_id_mjadwal
        //       });

        //       const baseURLGet =
        //         process.env.REACT_APP_BACKEND_URL_VERSION +
        //         "jawaban/jawaban-peserta"

        //       const requestOptions = {
        //         method: "PUT",
        //         headers: myHeaders,
        //         body: raw,
        //         redirect: "follow"
        //       };

        //       fetch(baseURLGet, requestOptions)
        //         .then(response => response.text())
        //         .then(result => {
        //           console.log(result)
        //           handledelete()
        //           Swal.close() // Close loading state
        //           Swal.fire(
        //             "Success",
        //             "Jawaban telah diupdate",
        //             "success"
        //           ).then(() => {
        //             localStorage.removeItem("access_session")
        //             var myHeaders = new Headers()
        //             myHeaders.append(
        //               "Authorization",
        //               "Bearer " + localStorage.getItem("access_token_2")
        //             )

        //             var requestOptions = {
        //               method: "PUT",
        //               headers: myHeaders,
        //               redirect: "follow",
        //             }

        //             fetch(
        //               process.env.REACT_APP_BACKEND_URL_VERSION +
        //               `Status-simulasi?status_simulasi=selesai&id_jadwal_asesi=${idSimulasi2}&id_simulasi=${idSimulasi}`,
        //               requestOptions
        //             )
        //               .then(response => response.json())
        //               .then(result => {
        //                 if (result.meta.code === 200) {
        //                   window.location.href = `http://utt-bpkp.alfahuma.com/dashboard`
        //                 }
        //               })
        //               .catch(error => console.log(""))
        //           })
        //         })
        //         .catch(error => {
        //           console.log("error", error)
        //           Swal.close() // Close loading state
        //           Swal.fire("Error", "Jawaban gagal diupdate", "error")
        //         })
        //     } else {
        //       const baseURL =
        //         process.env.REACT_APP_BACKEND_URL_VERSION +
        //         "jawaban/jawaban-peserta"

        //       var formdata = new FormData()
        //       formdata.append("id_peserta", userData.id_peserta)
        //       formdata.append("id_simulasi", idSimulasi)
        //       formdata.append("jawaban", JSON.stringify(juf))
        //       formdata.append("type", data[0].msn_mks_nama_kategori)
        //       formdata.append("id_jadwal", data[0].mjs_id_mjadwal)

        //       var myHeaders = new Headers()
        //       myHeaders.append(
        //         "Authorization",
        //         "Bearer " + localStorage.getItem("access_token_2")
        //       )

        //       var requestOptions = {
        //         method: "POST",
        //         headers: myHeaders,
        //         body: formdata,
        //         redirect: "follow",
        //       }

        //       fetch(baseURL, requestOptions)
        //         .then(response => response.text())
        //         .then(result => {
        //           console.log(result)
        //           Swal.close() // Close loading state
        //           Swal.fire(
        //             "Success",
        //             "Jawaban telah disimpan",
        //             "success"
        //           ).then(() => {
        //             console.log(data[0].data.mfm_id_simulasi)
        //             var myHeaders = new Headers()
        //             myHeaders.append(
        //               "Authorization",
        //               "Bearer " + localStorage.getItem("access_token_2")
        //             )

        //             var requestOptions = {
        //               method: "PUT",
        //               headers: myHeaders,
        //               redirect: "follow",
        //             }

        //             fetch(
        //               process.env.REACT_APP_BACKEND_URL_VERSION +
        //               `Status-simulasi?status_simulasi=selesai&id_jadwal_asesi=${idSimulasi2}&id_simulasi=${idSimulasi}`,
        //               requestOptions
        //             )
        //               .then(response => response.json())
        //               .then(result => {
        //                 if (result.meta.code === 200) {
        //                   window.location.href = `http://utt-bpkp.alfahuma.com/dashboard`
        //                 }
        //               })
        //               .catch(error => console.log(""))
        //           })
        //         })
        //         .catch(error => {
        //           console.log("error", error)
        //           Swal.close() // Close loading state
        //           Swal.fire("Error", "Jawaban gagal disimpan", "error")
        //         })
        //     }
        //   })
        //   .catch(error => {
        //     console.log("error", error)
        //     Swal.close() // Close loading state
        //     Swal.fire("Error", "External Error", "error")
        //   })
      }
    })
  }

  const handledelete = () => {
    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("access_token_2")
    )

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_BACKEND_URL_VERSION +
      "jawaban/waktu?id_peserta=" +
      userData.id_peserta +
      "&id_simulasi=" +
      idSimulasi,
      requestOptions
    )
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log("error", error))
  }

  const handleRemoveData = id => {
    setLoadingFile(true)
    const id_juf = id

    // const myHeaders = new Headers()
    // myHeaders.append(
    //   "Authorization",
    //   "Bearer " + localStorage.getItem("access_token_2")
    // )

    // const requestOptions = {
    //   method: "DELETE",
    //   headers: myHeaders,
    //   redirect: "follow",
    // }
    // const baseURL =
    //   process.env.REACT_APP_BACKEND_URL_VERSION +
    //   `jawaban/jawaban-file?id_juf=${id_juf}`
    // fetch(baseURL, requestOptions)
    //   .then(response => response.text())
    //   .then(result => {
    //     setUpdateFile(updateFIle + "1")
    //     setLoadingFile(false)
    //   })
    //   .catch(error => console.log("error", error))
  }

  // useEffect(() => {
  //   const fetchData = () => {
  //     if (autoSave === true) {
  //       const myHeaders = new Headers();
  //       myHeaders.append("Content-Type", "application/json");
  //       myHeaders.append("Authorization", "Bearer " + localStorage.getItem("access_token_2"));

  //       const raw = JSON.stringify({
  //         "id_peserta": userData.id_peserta,
  //         "jawaban": JSON.stringify(juf),
  //         "id_simulasi": idSimulasi,
  //         "id_jadwal": data[0].mjs_id_mjadwal
  //       });

  //       const baseURLGet =
  //         process.env.REACT_APP_BACKEND_URL_VERSION +
  //         "jawaban/jawaban-peserta"

  //       const requestOptions = {
  //         method: "PUT",
  //         headers: myHeaders,
  //         body: raw,
  //         redirect: "follow"
  //       };

  //       fetch(baseURLGet, requestOptions)
  //         .then(response => response.json())
  //         .then(result => {
  //           // Handle the result if needed
  //         })
  //         .catch(error => {
  //           // Handle errors if any
  //         })
  //     }
  //   }

  //   // Initial fetch
  //   fetchData()

  //   // Schedule subsequent fetch every 30 seconds
  //   const interval = setInterval(fetchData, 30000)

  //   // Clear interval on component unmount
  //   return () => clearInterval(interval)
  // }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {data && data[0] ? (
            <Breadcrumbs
              title="Simulasi"
              breadcrumbItem={data[0].mfm_ms_nama_simulasi}
            />
          ) : (
            <Breadcrumbs title="Simulasi" breadcrumbItem="Loading ...." />
          )}
          <Row>
            <Card>
              <CardBody>
                <div className="bg-primary bg-soft p-3 rounded">
                  <h3 className="font-size-25 text-primary mb-0">
                    {data && data[0] && data[0].mjs_durasi && (
                      <SessionTimer
                        data={data[0]}
                        editorData={JSON.stringify(juf)}
                        lastUpdate={lastUpdate}
                        idJadwal={data[0].mjs_id_mjadwal}
                      />
                    )}
                  </h3>
                </div>
              </CardBody>
            </Card>
          </Row>
          <Row>
            <div style={{ height: 700 }}>
              <SplitPane sizes={sizes} onChange={sizes => setSizes(sizes)}>
                <Pane minSize="30%" maxSize="100%">
                  <div style={{ ...layoutCSS, background: "white" }}>
                    <Col className="mx-1">
                      <Card>
                        <CardHeader className="bg-light">
                          <Row>
                            <Col className="col-8">
                              <Nav pills className="navtab-bg nav-justified">
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active: activeTab1 === "5",
                                    })}
                                    onClick={() => {
                                      toggle1("5")
                                    }}
                                  >
                                    Instruksi
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active: activeTab1 === "6",
                                    })}
                                    onClick={() => {
                                      toggle1("6")
                                    }}
                                  >
                                    Soal
                                  </NavLink>
                                </NavItem>
                                {data &&
                                  data[0] &&
                                  data[0].mjs_id_simulasi_referensi && (
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: activeTab1 === "7",
                                        })}
                                        onClick={() => {
                                          toggle1("7")
                                        }}
                                      >
                                        Referensi
                                      </NavLink>
                                    </NavItem>
                                  )}
                              </Nav>
                            </Col>
                            <Col className="d-flex aling-item-start justify-content-end">
                              <button
                                type="button"
                                onClick={() => {
                                  expand()
                                }}
                                className="btn noti-icon "
                              >
                                <i className="bx bx-fullscreen" />
                              </button>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody className="p-0">
                          <TabContent
                            activeTab={activeTab1}
                            className="p-3 text-muted"
                          >
                            <TabPane tabId="5">
                              <Row>
                                <Col sm="12">
                                  <div
                                    style={{
                                      height: defaultHeight,
                                      overflow: "auto",
                                    }}
                                  >
                                    {data &&
                                      data.map(
                                        (item, index) =>
                                          item.mfm_type === "Petunjuk" &&
                                          (item.mfm_mf_dir || item.mfm_file_petunjuk_dir) && (
                                            <ConverterSoal
                                              tipeData="Dokumen"
                                              linkData={
                                                item.mfm_mf_dir ? item.mfm_mf_dir : item.mfm_file_petunjuk_dir
                                              }
                                              key={index}
                                            />
                                          )
                                      )}
                                  </div>
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId="6">
                              <Row>
                                <Col sm="12">
                                  <div
                                    style={{
                                      height: defaultHeight,
                                      overflow: "auto",
                                    }}
                                  >
                                    {data &&
                                      data.map(
                                        (item, index) =>
                                          item.mfm_type !== "Petunjuk" && (
                                            <div key={index}>
                                              <ConverterSoal
                                                tipeData={item.mfm_type}
                                                linkData={item.mfm_mf_dir}
                                              />
                                            </div>
                                          )
                                      )}
                                  </div>
                                </Col>
                              </Row>
                            </TabPane>
                            {data &&
                              data[0] &&
                              data[0].mjs_id_simulasi_referensi && (
                                <TabPane tabId="7">
                                  <SoalText
                                    id_referensi={
                                      data[0].mjs_id_simulasi_referensi
                                    }
                                    id_jadwal={data[0].mjs_id_mjadwal}
                                  />
                                </TabPane>
                              )}
                          </TabContent>
                        </CardBody>
                      </Card>
                    </Col>
                  </div>
                </Pane>
                <Pane minSize="30%" maxSize="100%">
                  <div style={{ ...layoutCSS, background: "white" }}>
                    <Col>
                      <Card className="mx-1">
                        <CardHeader className="bg-light">
                          <Row>
                            <Col>
                              <CardTitle className="h2">Jawaban</CardTitle>
                            </Col>
                            <Col className="col-2 text-end">
                              <button
                                type="button"
                                onClick={() => {
                                  expandEditor()
                                }}
                                className="btn noti-icon "
                              >
                                <i className="bx bx-fullscreen" />
                              </button>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <div className="inner-repeater mb-4">
                            <Label>Upload File</Label>
                            <table style={{ width: "100%" }}>
                              <tbody>
                                {(rows1 || []).map((formRow, key) => (
                                  <tr key={key}>
                                    <td>
                                      <Row className="mb-2">
                                        <Col md="10">
                                          <Input
                                            type="file"
                                            className="inner form-control"
                                            onChange={e =>
                                              handleFileChange(formRow.id, e)
                                            }
                                          />
                                        </Col>
                                        <Col md="2">
                                          {formRow.id === 1 ? (
                                            <Button
                                              onClick={() => {
                                                handleAddRowNested()
                                              }}
                                              color="success"
                                              className="me-2 rounded-circle"
                                            >
                                              <i className="bx bx-plus" />
                                            </Button>
                                          ) : (
                                            <Button
                                              color="danger"
                                              id="unknown-btn"
                                              className="rounded-circle"
                                              onClick={e => {
                                                handleRemoveRow(formRow.id)
                                              }}
                                            >
                                              <i className="bx bx-minus" />
                                            </Button>
                                          )}
                                        </Col>
                                      </Row>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                            {listFile && <Label>List File</Label>}
                            <table style={{ width: "100%" }}>
                              <tbody>
                                {listFile &&
                                  listFile[0].map((item, index) => (
                                    <tr key={index}>
                                      {loadingFile === false ? (
                                        <td>
                                          <Row className="mb-2">
                                            <Col md="10">
                                              <Button
                                                color="success"
                                                style={{
                                                  width: "100%",
                                                  display: "flex",
                                                }}
                                              >
                                                <a
                                                  href={
                                                    process.env
                                                      .REACT_APP_BACKEND_URL +
                                                    item.juf_file_dir
                                                  }
                                                  download={item.juf_file_dir
                                                    .split("/")
                                                    .pop()}
                                                >
                                                  {item.juf_file_dir
                                                    .split("/")
                                                    .pop()}
                                                </a>
                                              </Button>
                                            </Col>
                                            <Col md="2">
                                              <Button
                                                color="danger"
                                                id="unknown-btn"
                                                className="rounded-circle"
                                                onClick={e => {
                                                  handleRemoveData(
                                                    item.id_jawaban_upload_file
                                                  )
                                                }}
                                              >
                                                <i className="bx bx-minus" />
                                              </Button>
                                            </Col>
                                          </Row>
                                        </td>
                                      ) : (
                                        <div className=" ms-2 mt-2 fw-bold">
                                          <div
                                            role="status"
                                            className="spinner-border text-dark spinner-border-sm"
                                          >
                                            <span className="visually-hidden">
                                              Loading...
                                            </span>
                                          </div>{" "}
                                          Loading...
                                        </div>
                                      )}
                                    </tr>
                                  ))}
                              </tbody>
                            </table>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </div>
                </Pane>
              </SplitPane>
            </div>
          </Row>
          <Row>
            <Card>
              <CardBody>
                <div className="d-flex flex-row-reverse bd-highlight">
                  <button
                    type="submit"
                    className="btn btn-soft-success ms-2 bd-highlight"
                    onClick={handleSubmit}
                  >
                    Selesai
                  </button>
                </div>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormEditors
