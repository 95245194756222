import React, { useState, useEffect, useMemo } from "react"
import { Link, useParams, useLocation, useNavigate } from "react-router-dom"
import {
  Form,
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Nav,
  NavItem,
  NavLink,
  CardText,
  TabContent,
  TabPane,
  Modal,
  CardHeader,
} from "reactstrap"

// Form Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import classnames from "classnames"
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import Presentation from "components/PresentationGenerator/Presentation"
import Swal from "sweetalert2"
import ConverterSoal from "components/ConverterSoal/ConverterSoal"
import SessionTimer from "components/CountdownTimer/SessionTimerPreview"
import Calculator from "components/Calculator/calculator"
import CheckStatus from "components/CheckStatus/CheckStatus"
import SplitPane, { Pane } from "split-pane-react"
import SoalText from "components/RefrensiJawaban/SoalText"
import Base64UploadAdapter from "components/Editor/Base64UploadAdapter"

const MemoizedPresentation = React.memo(Presentation)

const FormEditors = () => {
  //meta title
  document.title = "Soal Simulasi"
  const { id } = useParams()
  const { id2 } = useParams()
  const [activeTab1, setactiveTab1] = useState("5")
  const [editorData, setEditorData] = useState("")
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [pptx, setPptx] = useState()
  const userData = JSON.parse(localStorage.getItem("authUser"))
  const [modal, setModal] = useState(false)
  const [isHidden, setIsHidden] = useState(false)
  const [isHiddenEditor, setIsHiddenEditor] = useState(false)
  const [defaultHeight, setDefaultHight] = useState("400px")
  const [lastUpdate, setLastUpdate] = useState()
  const [expandStatus, setExpandStatus] = useState(false)
  const [windowState, setWindowState] = useState(500)
  const [autoSave, setAutoSave] = useState(false)

  const [sizes, setSizes] = useState(["100%", "100%"])

  const location = useLocation()
  const navigate = useNavigate()
  const [selectedOption, setSelectedOption] = useState()

  const handleChange = event => {
    let check = event.target.value;
    if (check === "text") {
      Swal.fire({
        title: "KONFIRMASI",
        text: "Anda yakin memilih jawaban tipe text",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
      }).then(result => {
        if (result.isConfirmed) {
          setSelectedOption(check)
          setIsHidden(true)
        }
      })
    } else if (check === "presentasi") {
      Swal.fire({
        title: "KONFIRMASI",
        text: "Anda yakin untuk memilih jawaban tipe ppt ?",
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
      }).then(result => {
        if (result.isConfirmed) {
          setSelectedOption(check)
          setIsHidden(true)
          Swal.fire({
            title: "Loading",
            text: "Please wait while we fetch the data...",
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
              Swal.showLoading()
            },
          })
          Swal.close()
          // const baseURLPost =
          //   process.env.REACT_APP_BACKEND_URL_VERSION + "jawaban/jawaban-ppt"
          // var myHeaders = new Headers()
          // myHeaders.append(
          //   "Authorization",
          //   "Bearer " + localStorage.getItem("access_token_2")
          // )

          // var formdata = new FormData()
          // formdata.append("id_peserta", userData.id_peserta)
          // formdata.append("id_simulasi", id)
          // formdata.append("id_jadwal", data[0].mjs_id_mjadwal)

          // var requestOptions = {
          //   method: "POST",
          //   headers: myHeaders,
          //   body: formdata,
          //   redirect: "follow",
          // }

          // fetch(baseURLPost, requestOptions)
          //   .then(response => response.json())
          //   .then(responseData => {
          setPptx('/dummy/')
          //     Swal.close()
          //   })
          //   .catch(error => console.log("error", error))
        }
      })
    }
  }
  const handleChangeText = (event, editor) => {
    const data = editor.getData()
    setEditorData(data)
    setAutoSave(true)
  }

  const layoutCSS = {
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  }

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  function expand() {
    if (expandStatus == false) {
      setSizes(["100%", 0])
      setExpandStatus(true)
      setDefaultHight("100vh")
      setWindowState(900)
    } else {
      setSizes(["100%", "100%"])
      setExpandStatus(false)
      setDefaultHight("400px")
      setWindowState(500)
    }
  }

  function expandEditor() {
    if (expandStatus == false) {
      setSizes([0, "100%"])
      setExpandStatus(true)
      setDefaultHight("100vh")
      setWindowState(900)
    } else {
      setSizes(["100%", "100%"])
      setExpandStatus(false)
      setDefaultHight("400px")
      setWindowState(500)
    }
  }

  const openKalkulator = () => {
    if (modal) {
      setModal(false)
    } else {
      setModal(true)
    }
  }
  //soal
  useEffect(() => {
    // CheckStatus(id2, id)
    setLoading(true)
    const baseURL =
      process.env.REACT_APP_BACKEND_URL_VERSION +
      "Soal-simulasi-preview?id_simulasi=" +
      id +
      "&id_asesi=" +
      id2
    Swal.fire({
      title: "Loading",
      text: "Please wait while we fetch the data...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading()
      },
    })
    fetch(baseURL, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token_2"),
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.meta.code === 200) {
          setData(responseData.data[0].data)
          const idJadwal = responseData.data[0].data[0].mjs_id_mjadwal
          // getJawaban(idJadwal)
          setLoading(false)
          Swal.close()
        } else {
          setLoading(false)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: responseData.meta.message,
          }).then(() => {
            // navigate(`/Profile`)
            // window.location.href = `http://utt-bpkp.alfahuma.com/dashboard`
          })
        }
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong! Please try again later.",
        }).then(() => {
          // navigate(`/Profile`)
          // window.location.href = `http://utt-bpkp.alfahuma.com/dashboard`
        })
      })
  }, [])

  // get data jawaban
  // const getJawaban = id_jadwal => {
  //   const baseURLGet =
  //     process.env.REACT_APP_BACKEND_URL_VERSION +
  //     `jawaban/jawaban-ppt?id_peserta=` +
  //     userData.id_peserta +
  //     `&id_simulasi=` +
  //     id +
  //     `&id_jadwal=` +
  //     id_jadwal

  //   fetch(baseURLGet, {
  //     method: "GET",
  //     headers: {
  //       Authorization: "Bearer " + localStorage.getItem("access_token_2"),
  //       "Content-Type": "application/json",
  //     },
  //   })
  //     .then(response => response.json())
  //     .then(responseData => {
  //       if (responseData.meta.code === 200) {
  //         setIsHidden(true)
  //         setSelectedOption("presentasi")
  //         setPptx(responseData.data[0].dir)
  //         Swal.close()
  //       } else {
  //         const baseURLGet =
  //           process.env.REACT_APP_BACKEND_URL_VERSION +
  //           `jawaban/jawaban-peserta?id_peserta=` +
  //           userData.id_peserta +
  //           `&id_simulasi=` +
  //           id +
  //           `&id_jadwal=` +
  //           id_jadwal

  //         fetch(baseURLGet, {
  //           method: "GET",
  //           headers: {
  //             Authorization: "Bearer " + localStorage.getItem("access_token_2"),
  //             "Content-Type": "application/json",
  //           },
  //         })
  //           .then(response => response.json())
  //           .then(responseData => {
  //             if (responseData.meta.code === 200) {
  //               if (responseData.data[0].data.value) {
  //                 setSelectedOption("text")
  //                 setIsHidden(true)
  //                 setEditorData(responseData.data[0].data.value)
  //                 setLastUpdate(responseData.data[0].data.updated_at)
  //                 Swal.close()
  //               }
  //             }
  //           })
  //           .catch(error => {
  //             Swal.close()
  //             console.log("")
  //           })
  //       }
  //     })
  //     .catch(error => {
  //       Swal.close()
  //     })
  // }
  //last update
  // useEffect(() => {
  //   if (data && data[0]) {
  //     const baseURLGet =
  //       process.env.REACT_APP_BACKEND_URL_VERSION +
  //       `monitoring/log-last?id_peserta=` +
  //       userData.id_peserta +
  //       `&id_simulasi=` +
  //       id +
  //       `&id_jadwal=` +
  //       data[0].mjs_id_mjadwal

  //     fetch(baseURLGet, {
  //       method: "GET",
  //       headers: {
  //         Authorization: "Bearer " + localStorage.getItem("access_token_2"),
  //         "Content-Type": "application/json",
  //       },
  //     })
  //       .then(response => response.json())
  //       .then(responseData => {
  //         // console.log(responseData)
  //         if (responseData.meta.code === 200) {
  //           if (responseData.data[0].value) {
  //             setLastUpdate(responseData.data[0].updated_at)
  //           }
  //         }
  //       })
  //       .catch(error => {
  //         console.log(error)
  //       })
  //   }
  // }, [data])

  useEffect(() => {
    const handleWheelEvent = event => {
      if (event.ctrlKey) {
        event.preventDefault()
      }
    }

    window.addEventListener("wheel", handleWheelEvent, { passive: false })

    return () => {
      window.removeEventListener("wheel", handleWheelEvent)
    }
  }, [])

  const handleSubmitPPT = () => {
    Swal.fire({
      title: "KONFIRMASI",
      text: "Anda yakin untuk menyelesaikan soal ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Loading",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading()
          },
        })

        window.history.back()

        // var myHeaders = new Headers()
        // myHeaders.append(
        //   "Authorization",
        //   "Bearer " + localStorage.getItem("access_token_2")
        // )

        // var requestOptions = {
        //   method: "PUT",
        //   headers: myHeaders,
        //   redirect: "follow",
        // }
        // fetch(
        //   process.env.REACT_APP_BACKEND_URL_VERSION +
        //   `Status-simulasi?status_simulasi=selesai&id_jadwal_asesi=${id2}&id_simulasi=${id}`,
        //   requestOptions
        // )
        //   .then(response => response.text())
        //   .then(result => {
        //     Swal.close() // Close loading state
        //     Swal.fire("Success", "Jawaban telah diupdate", "success").then(
        //       () => {
        //         localStorage.removeItem("access_session")
        //         handledelete()
        //         // navigate(`/Profile`)
        //         window.location.href = `http://utt-bpkp.alfahuma.com/dashboard`
        //       }
        //     )
        //   })
        //   .catch(error => console.log(""))
      }
    })
  }

  const handledelete = () => {
    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("access_token_2")
    )

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_BACKEND_URL_VERSION +
      "jawaban/waktu?id_peserta=" +
      userData.id_peserta +
      "&id_simulasi=" +
      id,
      requestOptions
    )
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log("error", error))
  }

  //Auto Save 
  // useEffect(() => {
  //   const fetchData = () => {
  //     if (autoSave === true) {
  //       const myHeaders = new Headers();
  //       myHeaders.append("Content-Type", "application/json");
  //       myHeaders.append("Authorization", "Bearer " + localStorage.getItem("access_token_2"));

  //       const raw = JSON.stringify({
  //         "id_peserta": userData.id_peserta,
  //         "jawaban": editorData,
  //         "id_simulasi": id,
  //         "id_jadwal": data[0].mjs_id_mjadwal
  //       });

  //       const baseURLGet =
  //         process.env.REACT_APP_BACKEND_URL_VERSION +
  //         'jawaban/jawaban-peserta'

  //       const requestOptions = {
  //         method: "PUT",
  //         headers: myHeaders,
  //         body: raw,
  //         redirect: "follow"
  //       };

  //       fetch(baseURLGet, requestOptions)
  //         .then((response) => response.json())
  //         .then((result) => {
  //           // Handle the result if needed
  //         })
  //         .catch((error) => {
  //           // Handle errors if any
  //         });
  //     }
  //   };

  //   // Initial fetch
  //   fetchData();

  //   // Schedule subsequent fetch every 30 seconds
  //   const interval = setInterval(fetchData, 30000);

  //   // Clear interval on component unmount
  //   return () => clearInterval(interval);
  // }, []);

  const handleSave = () => {
    Swal.fire({
      title: "KONFIRMASI",
      text: "Anda yakin untuk menyimpan jawaban ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Loading",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading()
          },
        }) // Display loading state

        // const baseURLGet =
        //   process.env.REACT_APP_BACKEND_URL_VERSION +
        //   `jawaban/jawaban-peserta?id_peserta=` +
        //   userData.id_peserta +
        //   `&id_simulasi=` +
        //   id +
        //   '&id_jadwal=' +
        //   data[0].mjs_id_mjadwal

        // fetch(baseURLGet, {
        //   method: "GET",
        //   headers: {
        //     Authorization: "Bearer " + localStorage.getItem("access_token_2"),
        //     "Content-Type": "application/json",
        //   },
        // })
        //   .then(response => response.json())
        //   .then(responseData => {
        //     if (responseData.meta.code === 200) {
        //       setLastUpdate(responseData.data[0].updated_at)
        //       const myHeaders = new Headers();
        //       myHeaders.append("Content-Type", "application/json");
        //       myHeaders.append("Authorization", "Bearer " + localStorage.getItem("access_token_2"));

        //       const raw = JSON.stringify({
        //         "id_peserta": userData.id_peserta,
        //         "jawaban": editorData,
        //         "id_simulasi": id,
        //         "id_jadwal": data[0].mjs_id_mjadwal
        //       });

        //       const requestOptions = {
        //         method: "PUT",
        //         headers: myHeaders,
        //         body: raw,
        //         redirect: "follow"
        //       };

        //       fetch(process.env.REACT_APP_BACKEND_URL_VERSION + "jawaban/jawaban-peserta", requestOptions)
        //         .then((response) => response.text())
        //         .then((result) => {
        Swal.close(); // Close loading state
        //           Swal.fire("Success", "Jawaban telah diupdate", "success").then(() => {
        //             getJawaban(data[0].mjs_id_mjadwal);
        //           });
        //         })
        //         .catch((error) => {
        //           console.log("error", error);
        //           Swal.close(); // Close loading state
        //           Swal.fire("Error", "Jawaban gagal diupdate", "error");
        //         });
        //     } else {
        //       const baseURL =
        //         process.env.REACT_APP_BACKEND_URL_VERSION +
        //         "jawaban/jawaban-peserta"

        //       var formdata = new FormData()
        //       formdata.append("id_peserta", userData.id_peserta)
        //       formdata.append("id_simulasi", id)
        //       formdata.append("jawaban", editorData)
        //       formdata.append("type", data[0].msn_mks_nama_kategori)
        //       formdata.append("id_jadwal", data[0].mjs_id_mjadwal)

        //       var myHeaders = new Headers()
        //       myHeaders.append(
        //         "Authorization",
        //         "Bearer " + localStorage.getItem("access_token_2")
        //       )

        //       var requestOptions = {
        //         method: "POST",
        //         headers: myHeaders,
        //         body: formdata,
        //         redirect: "follow",
        //       }

        //       fetch(baseURL, requestOptions)
        //         .then(response => response.json())
        //         .then(responseData => {
        //           console.log(responseData)
        //           if (responseData.meta.code === 200) {
        //             setLastUpdate(responseData.data[0].updated_at)
        //           }
        //           Swal.close() // Close loading state
        //           Swal.fire(
        //             "Success",
        //             "Jawaban telah disimpan",
        //             "success"
        //           ).then(() => {
        //             getJawaban(data[0].mjs_id_mjadwal);
        //           })
        //         })
        //         .catch(error => {
        //           console.log("error", error)
        //           Swal.close() // Close loading state
        //           Swal.fire("Error", "Jawaban gagal disimpan", "error")
        //         })
        //     }
        //   })
        //   .catch(error => {
        //     console.log("error", error)
        //     Swal.close() // Close loading state
        //     Swal.fire("Error", "External Error", "error")
        //   })
      }
    })
  }

  const handleSubmitText = () => {
    Swal.fire({
      title: "KONFIRMASI",
      text: "Anda yakin untuk menyelesaikan soal ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Loading",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading()
          },
        }) // Display loading state
        // console.log("test datav", data[0].data.mfm_id_simulasi)

        // localStorage.removeItem("access_session")
        // const baseURLGet =
        //   process.env.REACT_APP_BACKEND_URL_VERSION +
        //   `jawaban/jawaban-peserta?id_peserta=` +
        //   userData.id_peserta +
        //   `&id_simulasi=` +
        //   id +
        //   "&id_jadwal=" +
        //   data[0].mjs_id_mjadwal

        // fetch(baseURLGet, {
        //   method: "GET",
        //   headers: {
        //     Authorization: "Bearer " + localStorage.getItem("access_token_2"),
        //     "Content-Type": "application/json",
        //   },
        // })
        //   .then(response => response.json())
        //   .then(responseData => {
        //     console.log("Konfirmasi", responseData)
        //     if (responseData.meta.code === 200) {
        //       const myHeaders = new Headers();
        //       myHeaders.append("Content-Type", "application/json");
        //       myHeaders.append("Authorization", "Bearer " + localStorage.getItem("access_token_2"));

        //       const raw = JSON.stringify({
        //         "id_peserta": userData.id_peserta,
        //         "jawaban": editorData,
        //         "id_simulasi": id,
        //         "id_jadwal": data[0].mjs_id_mjadwal
        //       });

        //       const requestOptions = {
        //         method: "PUT",
        //         headers: myHeaders,
        //         body: raw,
        //         redirect: "follow"
        //       };

        //       fetch(process.env.REACT_APP_BACKEND_URL_VERSION + "jawaban/jawaban-peserta", requestOptions)
        //         .then((response) => response.text())
        //         .then(result => {
        //           handledelete()
        //           Swal.close() // Close loading state
        //           Swal.fire(
        //             "Success",
        //             "Jawaban telah diupdate",
        //             "success"
        //           ).then(() => {
        //             localStorage.removeItem("access_session")
        //             // navigate(`/Profile`)
        //             var myHeaders = new Headers()
        //             myHeaders.append(
        //               "Authorization",
        //               "Bearer " + localStorage.getItem("access_token_2")
        //             )

        //             var requestOptions = {
        //               method: "PUT",
        //               headers: myHeaders,
        //               redirect: "follow",
        //             }

        //             fetch(
        //               process.env.REACT_APP_BACKEND_URL_VERSION +
        //               `Status-simulasi?status_simulasi=selesai&id_jadwal_asesi=${id2}&id_simulasi=${id}`,
        //               requestOptions
        //             )
        //               .then(response => response.json())
        //               .then(result => {
        //                 if (result.meta.code === 200) {
        //                   window.location.href = `http://utt-bpkp.alfahuma.com/dashboard`
        //                 }
        //               })
        //               .catch(error => console.log(""))
        //           })
        //         })
        //         .catch(error => {
        //           console.log("error", error)
        //           Swal.close() // Close loading state
        //           Swal.fire("Error", "Jawaban gagal diupdate", "error")
        //         })
        //     } else {
        //       const baseURL =
        //         process.env.REACT_APP_BACKEND_URL_VERSION +
        //         "jawaban/jawaban-peserta"

        //       var formdata = new FormData()
        //       formdata.append("id_peserta", userData.id_peserta)
        //       formdata.append("id_simulasi", id)
        //       formdata.append("jawaban", editorData)
        //       formdata.append("type", data[0].msn_mks_nama_kategori)
        //       formdata.append("id_jadwal", data[0].mjs_id_mjadwal)

        //       var myHeaders = new Headers()
        //       myHeaders.append(
        //         "Authorization",
        //         "Bearer " + localStorage.getItem("access_token_2")
        //       )

        //       var requestOptions = {
        //         method: "POST",
        //         headers: myHeaders,
        //         body: formdata,
        //         redirect: "follow",
        //       }

        //       fetch(baseURL, requestOptions)
        //         .then(response => response.text())
        //         .then(result => {
        //           console.log(result)
        //           Swal.close() // Close loading state
        //           Swal.fire(
        //             "Success",
        //             "Jawaban telah disimpan",
        //             "success"
        //           ).then(() => {
        //             var myHeaders = new Headers()
        //             myHeaders.append(
        //               "Authorization",
        //               "Bearer " + localStorage.getItem("access_token_2")
        //             )

        //             var requestOptions = {
        //               method: "PUT",
        //               headers: myHeaders,
        //               redirect: "follow",
        //             }

        //             fetch(
        //               process.env.REACT_APP_BACKEND_URL_VERSION +
        //               `Status-simulasi?status_simulasi=selesai&id_jadwal_asesi=${id2}&id_simulasi=${id}`,
        //               requestOptions
        //             )
        //               .then(response => response.json())
        //               .then(result => {
        //                 // navigate(`/Profile`)
        //                 if (result.meta.code === 200) {
        //                   window.location.href = `http://utt-bpkp.alfahuma.com/dashboard`
        //                 }
        //               })
        //               .catch(error => console.log(""))


        //           })
        //         })
        //         .catch(error => {
        //           console.log("error", error)
        //           Swal.close() // Close loading state
        //           Swal.fire("Error", "Jawaban gagal disimpan", "error")
        //         })
        //   }
        // })
        // .catch(error => {
        //   console.log("error", error)
        //   Swal.close() // Close loading state
        //   Swal.fire("Error", "External Error", "error")
        // })
      }
    })
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {data && data[0] ? (
            <Breadcrumbs
              title="Simulasi"
              breadcrumbItem={data[0].mfm_ms_nama_simulasi}
            />
          ) : (
            <Breadcrumbs title="Simulasi" breadcrumbItem="Loading ...." />
          )}
          <Row>
            <Card>
              <CardBody>
                <div className="bg-primary bg-soft p-3 rounded">
                  <h3 className="font-size-25 text-primary mb-0">
                    {data &&
                      data[0] &&
                      data[0].mjs_durasi &&
                      selectedOption === "presentasi" ? (
                      <SessionTimer
                        data={data[0]}
                        editorData={pptx}
                        lastUpdate={lastUpdate}
                        idJadwal={data[0].mjs_id_mjadwal}
                      />
                    ) : data &&
                      data[0] &&
                      data[0].mjs_durasi &&
                      selectedOption === "text" ? (
                      <SessionTimer
                        data={data[0]}
                        editorData={editorData}
                        lastUpdate={lastUpdate}
                        idJadwal={data[0].mjs_id_mjadwal}
                      />
                    ) : (
                      ""
                    )}
                  </h3>
                </div>
              </CardBody>
            </Card>
          </Row>
          <Row>
            <div style={{ height: windowState }}>
              <SplitPane sizes={sizes} onChange={sizes => setSizes(sizes)}>
                <Pane minSize="30%" maxSize="100%">
                  <div style={{ ...layoutCSS, background: "white" }}>
                    <Col>
                      <Col>
                        <Card className="p-0 mx-0">
                          <CardHeader className="bg-light">
                            <Row>
                              <Col className="col-8">
                                <Nav pills className="navtab-bg nav-justified">
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                        active: activeTab1 === "5",
                                      })}
                                      onClick={() => {
                                        toggle1("5")
                                      }}
                                    >
                                      Instruksi
                                    </NavLink>
                                  </NavItem>
                                  <NavItem>
                                    <NavLink
                                      style={{ cursor: "pointer" }}
                                      className={classnames({
                                        active: activeTab1 === "6",
                                      })}
                                      onClick={() => {
                                        toggle1("6")
                                      }}
                                    >
                                      Soal
                                    </NavLink>
                                  </NavItem>
                                  {data &&
                                    data[0] &&
                                    data[0].mjs_id_simulasi_referensi && (
                                      <NavItem>
                                        <NavLink
                                          style={{ cursor: "pointer" }}
                                          className={classnames({
                                            active: activeTab1 === "7",
                                          })}
                                          onClick={() => {
                                            toggle1("7")
                                          }}
                                        >
                                          Referensi
                                        </NavLink>
                                      </NavItem>
                                    )}
                                </Nav>
                              </Col>
                              <Col className="d-flex aling-item-start justify-content-end">
                                <button
                                  type="button"
                                  onClick={() => {
                                    expand()
                                  }}
                                  className="btn noti-icon "
                                >
                                  <i className="bx bx-fullscreen" />
                                </button>
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody className="p-0">
                            <TabContent
                              activeTab={activeTab1}
                              className="p-3 text-muted"
                            >
                              <TabPane tabId="5">
                                <Row>
                                  <Col sm="12">
                                    <div
                                      style={{
                                        height: defaultHeight,
                                        overflow: "auto",
                                      }}
                                    >
                                      {data &&
                                        data.map(
                                          (item, index) =>
                                            item.mfm_type === "Petunjuk" &&
                                            (item.mfm_mf_dir || item.mfm_file_petunjuk_dir) && (
                                              <ConverterSoal
                                                tipeData="Dokumen"
                                                linkData={
                                                  item.mfm_mf_dir ? item.mfm_mf_dir : item.mfm_file_petunjuk_dir
                                                }
                                                key={index}
                                              />
                                            )
                                        )}
                                    </div>
                                  </Col>
                                </Row>
                              </TabPane>
                              <TabPane tabId="6">
                                <Row>
                                  <Col sm="12">
                                    <div
                                      style={{
                                        height: defaultHeight,
                                        overflow: "auto",
                                      }}
                                    >
                                      {data &&
                                        data.map(
                                          (item, index) =>
                                            item.mfm_type !== "Petunjuk" && (
                                              <div key={index}>
                                                <ConverterSoal
                                                  tipeData={item.mfm_type}
                                                  linkData={item.mfm_mf_dir}
                                                />
                                              </div>
                                            )
                                        )}
                                    </div>
                                  </Col>
                                </Row>
                              </TabPane>
                              {data &&
                                data[0] &&
                                data[0].mjs_id_simulasi_referensi && (
                                  <TabPane tabId="7">
                                    <SoalText
                                      id_referensi={
                                        data[0].mjs_id_simulasi_referensi
                                      }
                                      id_jadwal={data[0].mjs_id_mjadwal}
                                    />
                                  </TabPane>
                                )}
                            </TabContent>
                          </CardBody>
                        </Card>
                      </Col>
                    </Col>
                  </div>
                </Pane>
                <Pane minSize="30%" maxSize="100%">
                  <div style={{ ...layoutCSS, background: "white" }}>
                    <Col>
                      <Card>
                        <CardHeader className="bg-light mx-1">
                          <Row className="d-flex align-items-center">
                            <Col className="col-4">
                              <CardTitle className="h2">Jawaban</CardTitle>
                            </Col>
                            <Col className="col-8 text-end">
                              <div className="d-flex justify-content-between align-items-center">
                                {isHidden === false && (
                                  <select
                                    id="selectOption"
                                    value={selectedOption}
                                    onChange={handleChange}
                                    className="form-select"
                                  >
                                    <option>-- Pilih Tipe Jawaban --</option>
                                    <option value="text">Jawaban Text</option>
                                    <option value="presentasi">
                                      Jawaban Presentasi
                                    </option>
                                  </select>
                                )}

                                <button
                                  type="button"
                                  className="btn-sm btn btn-soft-primary ms-2 fs-6"
                                  onClick={openKalkulator}
                                >
                                  Kalkulator
                                </button>
                                <button
                                  type="button"
                                  onClick={() => {
                                    expandEditor()
                                  }}
                                  className="btn noti-icon ms-2"
                                >
                                  <i className="bx bx-fullscreen" />
                                </button>
                              </div>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody style={{ height: defaultHeight }}>
                          {selectedOption === "text" ? (
                            <Form method="post" onSubmit={handleSubmitText}>
                              <CKEditor
                                editor={ClassicEditor}
                                data={editorData}
                                onReady={editor => {
                                  editor.plugins.get('FileRepository').createUploadAdapter = loader => {
                                    // Configure CKEditor to use your custom upload adapter
                                    return new Base64UploadAdapter(loader);
                                  };
                                }}
                                onChange={handleChangeText}
                              />
                            </Form>
                          ) : selectedOption === "presentasi" ? (
                            data[0] &&
                            pptx && (
                              <MemoizedPresentation
                                dir={pptx}
                                id_peserta={userData.id_peserta}
                                id_simulasi={id}
                                id_jadwal={data[0].mjs_id_mjadwal}
                                mode={"edit"}
                              />
                            )
                          ) : (
                            ""
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  </div>
                </Pane>
              </SplitPane>
            </div>
          </Row>
          <Row>
            <Card>
              {selectedOption === "presentasi" ? (
                <CardBody>
                  <div className="d-flex flex-row-reverse bd-highlight">
                    <button
                      type="submit"
                      className="btn btn-soft-success ms-2 bd-highlight"
                      onClick={handleSubmitPPT}
                    >
                      Selesai
                    </button>
                  </div>
                </CardBody>
              ) : selectedOption === "text" ? (
                <CardBody>
                  <div className="d-flex justify-content-between">
                    <button
                      type="submit"
                      className="btn btn-soft-primary"
                      onClick={handleSave}
                    >
                      Simpan Jawaban
                    </button>
                    <button
                      type="submit"
                      className="btn btn-soft-success"
                      onClick={handleSubmitText}
                    >
                      Selesai
                    </button>
                  </div>
                </CardBody>
              ) : (
                ""
              )}
            </Card>
          </Row>
        </Container>
        <Modal isOpen={modal} toggle={openKalkulator}>
          <Calculator draggable="true" />
        </Modal>
      </div>
    </React.Fragment>
  )
}

export default FormEditors
