import React, { useState, useEffect } from "react"

import {
  Form,
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Nav,
  NavItem,
  NavLink,
  CardText,
  TabContent,
  TabPane,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
} from "reactstrap"
import { Status } from "pages/Jadwal/status"
import Swal from "sweetalert2"
import { io } from "socket.io-client"
import { use } from "i18next"

function SoalText(props) {
  const [valueReference, setValueReference] = useState()

  useEffect(() => {
    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("access_token_2")
    )

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_BACKEND_URL_VERSION +
      "jawaban/jawaban-refrensi?id_jadwal=" +
      props.id_jadwal,
      requestOptions
    )
      .then(response => response.json())
      .then(responseData => {
        if (responseData.meta.code === 200) {
          console.log(responseData.data[0].value)
          if (responseData.data[0].value) {
            setValueReference(responseData.data[0].value)
          }
        }
      })
      .catch(error => console.log("error", error))
  }, [props])

  function copyReference() {
    // Create a temporary element to parse the HTML content
    const tempElement = document.createElement("div")
    tempElement.innerHTML = valueReference

    // Get the plain text content without HTML tags
    const plainText = tempElement.textContent || tempElement.innerText

    // Copy the plain text to the clipboard
    navigator.clipboard.writeText(plainText)
  }

  return (
    <Card>
      <CardHeader>
        <Row>
          <Col sm="11">
            <div
              style={{ maxHeight: '400px', overflowY: 'auto' }} // Set max height and enable vertical scrolling
              dangerouslySetInnerHTML={{ __html: valueReference }}
            />
          </Col>
          <Col sm="1">
            <Button
              className="btn btn-soft-primary"
              onClick={() => copyReference()}
            >
              <span className="mdi mdi-clipboard-file-outline"></span>
            </Button>
          </Col>
        </Row>
      </CardHeader>
    </Card>

  )
}

export default SoalText
