import React, { useState, useEffect, useRef } from "react"
import { Document, Page, pdfjs } from "react-pdf"
import videojs from "video.js";
import 'video.js/dist/video-js.min.css';
import '@videojs/http-streaming';
import { Magnifier, GlassMagnifier } from 'react-image-magnifiers';
// import '@videojs/flash';

const ConverterSoal = (data, linkData) => {
    const [numPages, setNumPages] = useState(null)
    const [pageNumber, setPageNumber] = useState(1)
    const [pdfUrl, setPdfUrl] = useState()
    const [dataUrlPdf, setDataUrlPdf] = useState()
    const [zoom, setZoom] = useState(1);
    const videoRef = useRef(null);
    const playerRef = useRef(null);
    const [videoUrl, setVideoUrl] = useState()
    const [imagesUrl, setImagesUrl] = useState()

    // set data 
    useEffect(() => {
        if (data.linkData) {
            if (data.tipeData === 'Dokumen') {
                setDataUrlPdf(process.env.REACT_APP_FILE_DIGIMAC_URL + data.linkData)
            } else if (data.tipeData === 'Video') {
                setVideoUrl(process.env.REACT_APP_FILE_DIGIMAC_URL + data.linkData)
            }
            else if (data.tipeData === 'Foto') {
                setImagesUrl(process.env.REACT_APP_FILE_DIGIMAC_URL + data.linkData)
            } else if (data.tipeData === 'Petunjuk') {
                setDataUrlPdf(process.env.REACT_APP_FILE_DIGIMAC_URL + data.linkData)
            }
        }
    }, [data.linkData])

    //Video
    useEffect(() => {
        // instantiate the video.js player
        if (videoUrl) {
            const player = videojs(videoRef.current, {
                autoplay: false,
                controls: true,
                sources: [
                    {
                        src: videoUrl.replace(/[\u200B-\u200D\uFEFF]/g, ''),
                        type: "video/mp4",
                    },
                    {
                        src: videoUrl.replace(/[\u200B-\u200D\uFEFF]/g, ''),
                        type: "video/webm",
                    },
                    {
                        src: videoUrl.replace(/[\u200B-\u200D\uFEFF]/g, ''),
                        type: "video/x-matroska", // for .mkv files
                    },
                    // add more objects for other video types as needed
                ],
            });
            // store the player instance in a ref
            playerRef.current = player;
            return () => {
                // destroy the player on unmount
                playerRef.current.dispose();
            };
        }
    }, [videoUrl]);

    //Pdf
    const handleZoom = (event) => {
        if (event.ctrlKey || event.metaKey) {
            if (event.deltaY < 0) {
                setZoom((prevZoom) => prevZoom + 0.1);
            } else if (event.deltaY > 0) {
                setZoom((prevZoom) => Math.max(0.1, prevZoom - 0.1));
            }
            event.preventDefault();
        }
        // console.log(window.innerWidth)
    };

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    })
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages)
    }

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(dataUrlPdf.replace(/[\u200B-\u200D\uFEFF]/g, ''), {
                responseType: "arraybuffer",
            })
            // console.log(response)
            const blob = new Blob([await response.arrayBuffer()], { type: "application/pdf" })
            const url = URL.createObjectURL(blob)
            setPdfUrl(url)
        }
        if (dataUrlPdf) {
            fetchData()
        }
    }, [dataUrlPdf])

    switch (data.tipeData) {
        case "Dokumen":
            return (
                <div onWheel={handleZoom} className='pdf-container'>
                    <Document
                        file={pdfUrl}
                        onLoadSuccess={onDocumentLoadSuccess}
                        options={{
                            cMapUrl: 'cmaps/',
                            cMapPacked: true,
                            workerSrc: `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`,
                        }}
                    >
                        {Array.from({ length: numPages }, (_, index) => (
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                width={window.innerWidth * zoom}
                                height={window.innerHeight * zoom}
                            />
                        ))}
                    </Document>
                </div>

            )
        case "Petunjuk":
            return (
                <div onWheel={handleZoom} className='pdf-container'>
                    <Document
                        file={pdfUrl}
                        onLoadSuccess={onDocumentLoadSuccess}
                        options={{
                            cMapUrl: 'cmaps/',
                            cMapPacked: true,
                            workerSrc: `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`,
                        }}
                    >
                        {Array.from({ length: numPages }, (_, index) => (
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                width={window.innerWidth * zoom}
                                height={window.innerHeight * zoom}
                            />
                        ))}
                    </Document>
                </div>

            )
        case "Video":
            return (
                <div className="video-container" style={{ position: 'relative', width: '100%', height: '300px' }}>
                    <div data-vjs-player>
                        <video ref={videoRef} className="video-js" style={{ position: 'absolute', maxWidth: '100%', maxHeight: '100%' }} />
                    </div>
                </div>
            )
        case "Foto":
            return (
                <div>
                    {imagesUrl ? (
                        <Magnifier
                            imageSrc={imagesUrl.replace(/[\u200B-\u200D\uFEFF]/g, '')}
                            imageAlt="Zoomable Image"
                            magnifierSize="200px"
                            cursorStyle="crosshair"
                            cursorBorderColor="#fff"
                            cursorWidth={2}
                            cursorHeight={2}
                        />
                    ) : (
                        <div>
                            Loading ...
                        </div>
                    )}
                </div >
            )
    }
}

export default ConverterSoal