import React, { useState, useEffect } from "react"
import { Link, useParams } from "react-router-dom"
import {
  Card,
  CardBody,
  CardText,
  CardTitle,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap"
import SessionTimer from "components/CountdownTimer/SessionTimer"

import DataPeseta from "./DataPeserta"
import LogSimulasi from "./LogSimulasi"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import Swal from "sweetalert2"

import classnames from "classnames"
import InterviewMonitoring from "./InterviewMonitoring"
import SessionTimerAdmin from "../../../components/CountdownTimer/SessionTimerAdmin"
function UiTabsAccordions(props) {
  //meta title
  document.title = "Simulai | BPKP"

  const [customActiveTab, setcustomActiveTab] = useState("1")
  const { idSimulasi } = useParams()
  const { idPeserta } = useParams()
  const { idJadwal } = useParams()
  const { simulasi } = useParams()
  const [dataPeserta, SetDataPeserta] = useState()
  const [dataLogs, setDataLogs] = useState()
  const [loading, setLoading] = useState()
  const [loading2, setLoading2] = useState()
  const [loading3, setLoading3] = useState()
  const [lastUpdate, setLastUpdate] = useState()
  const [firstUpdate, setFirstUpdate] = useState()
  const [jawaban, setJawaban] = useState()
  const [jawabanUrut, setJawabanUrut] = useState()
  const [tipe, setTipe] = useState()
  const [time, setTime] = useState()
  const [status, setStatus] = useState()
  const [dataJawaban, setDataJawaban] = useState()

  console.log(idSimulasi)

  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab)
    }
  }
  //data peserta
  useEffect(() => {
    setLoading(true)
    const baseURL =
      process.env.REACT_APP_BACKEND_URL_VERSION +
      "Detail-simulasi?id_peserta=" +
      idPeserta +
      "&id_simulasi=" +
      idSimulasi +
      `&id_jadwal=` +
      idJadwal

    Swal.fire({
      title: "Loading",
      text: "Please wait while we fetch the data...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading()
      },
    })
    fetch(baseURL, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token_2"),
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(responseData => {
        SetDataPeserta(responseData.data[0].peserta)
        setStatus(responseData.data[0].status)
        setLoading(false)
        Swal.close()
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong! Please try again later.",
        }).then(() => {
          window.history.back()
        })
      })
  }, [])
  //jawaban peserta
  useEffect(() => {
    setLoading2(true)
    const baseURLGet =
      process.env.REACT_APP_BACKEND_URL_VERSION +
      `jawaban/jawaban-peserta?id_peserta=` +
      idPeserta +
      `&id_simulasi=` +
      idSimulasi +
      `&id_jadwal=` +
      idJadwal

    fetch(baseURLGet, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token_2"),
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(responseData => {
        // console.log(responseData)
        if (responseData.meta.code === 200) {
          if (responseData.data[0]) {
            setDataJawaban(responseData.data[0])
            setLastUpdate(responseData.data[0].data.updated_at)
            setJawaban(responseData.data[0].data.value)
            setTipe(responseData.data[0].data.tipe)
            setJawabanUrut(responseData.data[0].data_soal)
            Swal.close()
            setLoading2(false)
          }
        }
      })
      .catch(error => {
        console.log(error)
      })
  }, [])

  //log jawaban
  useEffect(() => {
    setLoading3(true)
    const fetchData = () => {
      var myHeaders = new Headers()
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("access_token_2")
      )

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      }

      const baseURLlogs =
        process.env.REACT_APP_BACKEND_URL_VERSION +
        "monitoring/log?id_peserta=" +
        idPeserta +
        "&id_simulasi=" +
        idSimulasi +
        "&id_jadwal=" +
        idJadwal

      fetch(baseURLlogs, requestOptions)
        .then(response => response.json())
        .then(result => {
          setDataLogs(result.data[0])
          const dataCount = result?.data[0]?.length || 0;
          setFirstUpdate(result.data[0][dataCount - 1].updated_at)
          setTime(result.data[0][0].timer)
          Swal.close()
          setLoading3(false)
        })
        .catch(error => {
          setLoading3(false)
        })
    }

    // Fetch data immediately
    fetchData()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs
            title="Dashboard"
            breadcrumbItem={"Monitoring " + simulasi}
          />
          <Row>
            <Col>
              {dataLogs && simulasi != "INTERVIEW" && status && status.status ? (
                <SessionTimerAdmin
                  id_peserta={idPeserta}
                  id_simulasi={idSimulasi}
                  id_jadwal={idJadwal}
                  lastUpdate={lastUpdate}
                  status={status.status}
                  firstUpdate={firstUpdate}
                  timer={time}
                />
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              {loading === true ? (
                <div className=" ms-2 mt-2 fw-bold">
                  <div
                    role="status"
                    className="spinner-border text-dark spinner-border-sm"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>{" "}
                  Loading...
                </div>
              ) : (
                <DataPeseta data={dataPeserta} />
              )}
            </Col>
            <Col lg="12">
              {loading2 === true && loading3 === true ? (
                <div className="ms-2 mt-2 fw-bold">
                  <div
                    role="status"
                    className="spinner-border text-dark spinner-border-sm"
                  >
                    <span className="visually-hidden">Loading...</span>
                  </div>
                  Loading...
                </div>
              ) : (
                <>
                  {dataLogs && status && simulasi !== "INTERVIEW" && status && status.status && (
                    <LogSimulasi
                      data={dataLogs}
                      status={status.status}
                      jawaban={jawaban}
                      jawabanUrut={jawabanUrut}
                      tipe={tipe}
                      id_simulasi={idSimulasi}
                      id_peserta={idPeserta}
                      id_jadwal={idJadwal}
                    />
                  )}
                  {simulasi === "INTERVIEW" && (
                    <InterviewMonitoring
                      idPeserta={idPeserta}
                      idJadwal={idJadwal}
                      idSimulasi={idSimulasi}
                      nama_simulasi={simulasi}
                    />
                  )}
                </>
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UiTabsAccordions
