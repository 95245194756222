import React, { useState, useEffect } from "react"
import { Link, useParams, useLocation, useNavigate } from "react-router-dom"
import {
  Form,
  Card,
  CardBody,
  Col,
  Row,
  CardTitle,
  Container,
  Nav,
  NavItem,
  NavLink,
  CardText,
  TabContent,
  TabPane,
  CardImg,
  CardHeader,
} from "reactstrap"

// Form Editor
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import classnames from "classnames"
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { isEmpty, map } from "lodash"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import Swal from "sweetalert2"
import ConverterSoal from "components/ConverterSoal/ConverterSoal"
import CheckStatus from "components/CheckStatus/CheckStatus"
import SessionTimer from "components/CountdownTimer/SessionTimer"
import SplitPane, { Pane } from "split-pane-react"
import SoalText from "components/RefrensiJawaban/SoalText"
import Base64UploadAdapter from "components/Editor/Base64UploadAdapter"

const FormEditors = () => {
  //meta title
  document.title = "Soal Simulasi"
  const { id } = useParams()
  const { id2 } = useParams()
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const [verticalActiveTab, setverticalActiveTab] = useState(1)
  const [editorData, setEditorData] = useState("")
  const userData = JSON.parse(localStorage.getItem("authUser"))
  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  const [activeTab1, setactiveTab1] = useState("6")
  const [activeCard, setactiveCard] = useState("7")
  const [activeBar, setactiveBar] = useState("8")
  const [activeCardEditor, setactiveCardEditor] = useState("5")

  const [activeSidebar, setactiveSidebar] = useState("4")
  const [Visible, Invisible] = useState("visible")
  const [isHidden, setIsHidden] = useState(false)
  const [isHiddenEditor, setIsHiddenEditor] = useState(false)
  const [defaultHeight, setDefaultHight] = useState("400px")
  const [lastUpdate, setLastUpdate] = useState()
  const [expandStatus, setExpandStatus] = useState(false)
  const [autoSave, setAutoSave] = useState(false)
  const [sizes, setSizes] = useState(["100%", "100%"])
  const location = useLocation()
  const navigate = useNavigate()
  const [jawabanLoading, setJawabanLoading] = useState(true)


  const layoutCSS = {
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
  }

  const toggle1 = tab => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab)
    }
  }

  function expand() {
    if (expandStatus == false) {
      setSizes(["100%", 0])
      setExpandStatus(true)
      setDefaultHight("100vh")
    } else {
      setSizes(["100%", "100%"])
      setExpandStatus(false)
      setDefaultHight("400px")
    }
  }

  function expandEditor() {
    if (expandStatus == false) {
      setSizes([0, "100%"])
      setExpandStatus(true)
      setDefaultHight("100vh")
    } else {
      setSizes(["100%", "100%"])
      setExpandStatus(false)
      setDefaultHight("400px")
    }
  }

  function Sidebar() {
    if (activeSidebar == "4") {
      setactiveSidebar("1")
      setactiveBar("11")
      Invisible("invisible")
    } else {
      setactiveSidebar("4")
      setactiveBar("8")
      Invisible("visible")
    }
  }

  const [activeTabVartical, setoggleTabVertical] = useState(1)
  const [passedStepsVertical, setPassedStepsVertical] = useState([1])

  function toggleTabVertical(tab) {
    if (activeTabVartical !== tab) {
      var modifiedSteps = [...passedStepsVertical, tab]

      if (tab >= 1 && tab <= 4) {
        setoggleTabVertical(tab)
        setPassedStepsVertical(modifiedSteps)
      }
    }
  }

  useEffect(() => {
    setLoading(true)
    const baseURL =
      process.env.REACT_APP_BACKEND_URL_VERSION +
      "Soal-simulasi?id_simulasi=" +
      id +
      "&id_asesi=" +
      id2
    Swal.fire({
      title: "Loading",
      text: "Please wait while we fetch the data...",
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading()
      },
    })
    fetch(baseURL, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token_2"),
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(responseData => {
        if (responseData.meta.code === 200) {
          setData(responseData.data[0].data)
          setLoading(false)
          Swal.close()
        } else {
          setLoading(false)
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: responseData.meta.message,
          }).then(() => {
            window.location.href = `https://mac.bpkp.go.id/assessment_utt/dashboard`
          })
        }
      })
      .catch(error => {
        console.log(error)
        setLoading(false)
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong! Please try again later.",
        }).then(() => {
          window.location.href = `https://mac.bpkp.go.id/assessment_utt/dashboard`
        })
      })
  }, [])

  const getJawaban = () => {
    setJawabanLoading(true)
    const baseURLGet =
      process.env.REACT_APP_BACKEND_URL_VERSION +
      `jawaban/jawaban-peserta?id_peserta=` +
      userData.id_peserta +
      `&id_simulasi=` +
      id +
      `&id_jadwal=` +
      data[0].mjs_id_mjadwal

    fetch(baseURLGet, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + localStorage.getItem("access_token_2"),
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .then(responseData => {
        console.log(responseData)
        if (responseData.meta.code === 200) {
          if (responseData.data[0].data.value) {
            setEditorData(responseData.data[0].data.value)
            setLastUpdate(responseData.data[0].data.updated_at)
          }
        }
        setJawabanLoading(false)
      })
      .catch(error => {
        console.log(error)
        setJawabanLoading(false)
      })
  }

  useEffect(() => {
    if (data && data[0]) {
      getJawaban()
    }
  }, [data])

  useEffect(() => {
    const handleWheelEvent = event => {
      if (event.ctrlKey) {
        event.preventDefault()
      }
    }

    window.addEventListener("wheel", handleWheelEvent, { passive: false })

    return () => {
      window.removeEventListener("wheel", handleWheelEvent)
    }
  }, [])

  const handleChange = (event, editor) => {
    const data = editor.getData()
    setEditorData(data)
    setAutoSave(true)
  }

  const handleSubmit = () => {
    Swal.fire({
      title: "KONFIRMASI",
      text: "Anda yakin untuk menyelesaikan soal ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Loading",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading()
          },
        }) // Display loading state

        const baseURLGet =
          process.env.REACT_APP_BACKEND_URL_VERSION +
          `jawaban/jawaban-peserta?id_peserta=` +
          userData.id_peserta +
          `&id_simulasi=` +
          id +
          `&id_jadwal=` +
          data[0].mjs_id_mjadwal

        fetch(baseURLGet, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token_2"),
            "Content-Type": "application/json",
          },
        })
          .then(response => response.json())
          .then(responseData => {
            console.log("Konfirmasi", responseData)
            if (responseData.meta.code === 200) {
              const myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/json");
              myHeaders.append("Authorization", "Bearer " + localStorage.getItem("access_token_2"));

              const raw = JSON.stringify({
                "id_peserta": userData.id_peserta,
                "jawaban": editorData,
                "id_simulasi": id,
                "id_jadwal": data[0].mjs_id_mjadwal
              });

              const baseURLGet =
                process.env.REACT_APP_BACKEND_URL_VERSION +
                "jawaban/jawaban-peserta"

              const requestOptions = {
                method: "PUT",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
              };

              fetch(baseURLGet, requestOptions)
                .then(response => response.text())
                .then(result => {
                  handledelete()
                  console.log(result)
                  Swal.close() // Close loading state
                  Swal.fire(
                    "Success",
                    "Jawaban telah diupdate",
                    "success"
                  ).then(() => {
                    // console.log(mfm_id_simulasi,)

                    localStorage.removeItem("access_session")
                    var myHeaders = new Headers()
                    myHeaders.append(
                      "Authorization",
                      "Bearer " + localStorage.getItem("access_token_2")
                    )

                    var requestOptions = {
                      method: "PUT",
                      headers: myHeaders,
                      redirect: "follow",
                    }

                    fetch(
                      process.env.REACT_APP_BACKEND_URL_VERSION +
                      `Status-simulasi?status_simulasi=selesai&id_jadwal_asesi=${id2}&id_simulasi=${id}`,
                      requestOptions
                    )
                      .then(response => response.json())
                      .then(result => {
                        if (result.meta.code === 200) {
                          window.location.href = `https://mac.bpkp.go.id/assessment_utt/dashboard`
                        }
                      })
                      .catch(error => console.log(error))
                  })
                })
                .catch(error => {
                  console.log("error", error)
                  Swal.close() // Close loading state
                  Swal.fire("Error", "Jawaban gagal diupdate", "error")
                })
            } else {
              const baseURL =
                process.env.REACT_APP_BACKEND_URL_VERSION +
                "jawaban/jawaban-peserta"

              var formdata = new FormData()
              formdata.append("id_peserta", userData.id_peserta)
              formdata.append("id_simulasi", id)
              formdata.append("jawaban", editorData)
              formdata.append("type", data[0].msn_mks_nama_kategori)
              formdata.append("id_jadwal", data[0].mjs_id_mjadwal)

              var myHeaders = new Headers()
              myHeaders.append(
                "Authorization",
                "Bearer " + localStorage.getItem("access_token_2")
              )

              var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow",
              }

              fetch(baseURL, requestOptions)
                .then(response => response.text())
                .then(result => {
                  console.log(result)
                  Swal.close() // Close loading state
                  Swal.fire(
                    "Success",
                    "Jawaban telah disimpan",
                    "success"
                  ).then(() => {
                    console.log(mfm_id_simulasi)
                    var myHeaders = new Headers()
                    myHeaders.append(
                      "Authorization",
                      "Bearer " + localStorage.getItem("access_token_2")
                    )

                    var requestOptions = {
                      method: "PUT",
                      headers: myHeaders,
                      redirect: "follow",
                    }

                    fetch(
                      process.env.REACT_APP_BACKEND_URL_VERSION +
                      `Status-simulasi?status_simulasi=selesai&id_jadwal_asesi=${id2}&id_simulasi=${id}`,
                      requestOptions
                    )
                      .then(response => response.json())
                      .then(result => {
                        if (result.meta.code === 200) {
                          window.location.href = `https://mac.bpkp.go.id/assessment_utt/dashboard`
                        }
                      })
                      .catch(error => console.log(""))
                  })
                })
                .catch(error => {
                  console.log("error", error)
                  Swal.close() // Close loading state
                  Swal.fire("Error", "Jawaban gagal disimpan", "error")
                })
            }
          })
          .catch(error => {
            const baseURL =
              process.env.REACT_APP_BACKEND_URL_VERSION +
              "jawaban/jawaban-peserta"

            var formdata = new FormData()
            formdata.append("id_peserta", userData.id_peserta)
            formdata.append("id_simulasi", id)
            formdata.append("jawaban", editorData)
            formdata.append("type", data[0].msn_mks_nama_kategori)
            formdata.append("id_jadwal", data[0].mjs_id_mjadwal)

            var myHeaders = new Headers()
            myHeaders.append(
              "Authorization",
              "Bearer " + localStorage.getItem("access_token_2")
            )

            var requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: formdata,
              redirect: "follow",
            }

            fetch(baseURL, requestOptions)
              .then(response => response.text())
              .then(result => {
                console.log(result)
                Swal.close() // Close loading state
                Swal.fire(
                  "Success",
                  "Jawaban telah disimpan",
                  "success"
                ).then(() => {
                  console.log(mfm_id_simulasi)
                  var myHeaders = new Headers()
                  myHeaders.append(
                    "Authorization",
                    "Bearer " + localStorage.getItem("access_token_2")
                  )

                  var requestOptions = {
                    method: "PUT",
                    headers: myHeaders,
                    redirect: "follow",
                  }

                  fetch(
                    process.env.REACT_APP_BACKEND_URL_VERSION +
                    `Status-simulasi?status_simulasi=selesai&id_jadwal_asesi=${id2}&id_simulasi=${id}`,
                    requestOptions
                  )
                    .then(response => response.json())
                    .then(result => {
                      if (result.meta.code === 200) {
                        window.location.href = `https://mac.bpkp.go.id/assessment_utt/dashboard`
                      }
                    })
                    .catch(error => console.log(""))
                })
              })
              .catch(error => {
                console.log("error", error)
                Swal.close() // Close loading state
                Swal.fire("Error", "Jawaban gagal disimpan", "error")
              })
          })
      }
    })
  }

  const handleSave = () => {
    Swal.fire({
      title: "KONFIRMASI",
      text: "Anda yakin untuk menyimpan jawaban ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Loading",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading()
          },
        }) // Display loading state

        const baseURLGet =
          process.env.REACT_APP_BACKEND_URL_VERSION +
          `jawaban/jawaban-peserta?id_peserta=` +
          userData.id_peserta +
          `&id_simulasi=` +
          id +
          `&id_jadwal=` +
          data[0].mjs_id_mjadwal

        fetch(baseURLGet, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + localStorage.getItem("access_token_2"),
            "Content-Type": "application/json",
          },
        })
          .then(response => response.json())
          .then(responseData => {
            if (responseData.meta.code === 200) {
              setLastUpdate(responseData.data[0].updated_at)

              const myHeaders = new Headers();
              myHeaders.append("Content-Type", "application/json");
              myHeaders.append("Authorization", "Bearer " + localStorage.getItem("access_token_2"));

              const raw = JSON.stringify({
                "id_peserta": userData.id_peserta,
                "jawaban": editorData,
                "id_simulasi": id,
                "id_jadwal": data[0].mjs_id_mjadwal
              });

              const baseURLGet =
                process.env.REACT_APP_BACKEND_URL_VERSION +
                "jawaban/jawaban-peserta"

              const requestOptions = {
                method: "PUT",
                headers: myHeaders,
                body: raw,
                redirect: "follow"
              };

              fetch(baseURLGet, requestOptions)
                .then(response => response.text())
                .then(result => {
                  console.log(result)
                  Swal.close() // Close loading state
                  Swal.fire(
                    "Success",
                    "Jawaban telah diupdate",
                    "success"
                  ).then(() => {
                    getJawaban()
                  })
                })
                .catch(error => {
                  console.log("error", error)
                  Swal.close() // Close loading state
                  Swal.fire("Error", "Jawaban gagal diupdate", "error")
                })
            } else {
              const baseURL =
                process.env.REACT_APP_BACKEND_URL_VERSION +
                "jawaban/jawaban-peserta"

              var formdata = new FormData()
              formdata.append("id_peserta", userData.id_peserta)
              formdata.append("id_simulasi", id)
              formdata.append("jawaban", editorData)
              formdata.append("type", data[0].msn_mks_nama_kategori)
              formdata.append("id_jadwal", data[0].mjs_id_mjadwal)

              var myHeaders = new Headers()
              myHeaders.append(
                "Authorization",
                "Bearer " + localStorage.getItem("access_token_2")
              )

              var requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: formdata,
                redirect: "follow",
              }

              fetch(baseURL, requestOptions)
                .then(response => response.json())
                .then(responseData => {
                  if (responseData.meta.code === 200) {
                    setLastUpdate(responseData.data[0].updated_at)
                  }
                  Swal.close() // Close loading state
                  Swal.fire(
                    "Success",
                    "Jawaban telah disimpan",
                    "success"
                  ).then(() => {
                    getJawaban()
                  })
                })
                .catch(error => {
                  console.log("error", error)
                  Swal.close() // Close loading state
                  Swal.fire("Error", "Jawaban gagal disimpan", "error")
                })
            }
          })
          .catch(error => {
            const baseURL =
              process.env.REACT_APP_BACKEND_URL_VERSION +
              "jawaban/jawaban-peserta"

            var formdata = new FormData()
            formdata.append("id_peserta", userData.id_peserta)
            formdata.append("id_simulasi", id)
            formdata.append("jawaban", editorData)
            formdata.append("type", data[0].msn_mks_nama_kategori)
            formdata.append("id_jadwal", data[0].mjs_id_mjadwal)

            var myHeaders = new Headers()
            myHeaders.append(
              "Authorization",
              "Bearer " + localStorage.getItem("access_token_2")
            )

            var requestOptions = {
              method: "POST",
              headers: myHeaders,
              body: formdata,
              redirect: "follow",
            }

            fetch(baseURL, requestOptions)
              .then(response => response.json())
              .then(responseData => {
                if (responseData.meta.code === 200) {
                  setLastUpdate(responseData.data[0].updated_at)
                }
                Swal.close() // Close loading state
                Swal.fire(
                  "Success",
                  "Jawaban telah disimpan",
                  "success"
                ).then(() => {
                  getJawaban()
                })
              })
              .catch(error => {
                console.log("error", error)
                Swal.close() // Close loading state
                Swal.fire("Error", "Jawaban gagal disimpan", "error")
              })
          })
      }
    })
  }

  const handledelete = () => {
    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("access_token_2")
    )

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    }

    fetch(
      process.env.REACT_APP_BACKEND_URL_VERSION +
      "jawaban/waktu?id_peserta=" +
      userData.id_peserta +
      "&id_simulasi=" +
      id,
      requestOptions
    )
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log("error", error))
  }

  const correctImageUrl = (originalUrl) => {
    // Find the position of "https://dev.alfahuma.tech" in the URL
    const baseUrlIndex = originalUrl.indexOf("https://dev.alfahuma.tech");

    // If "https://dev.alfahuma.tech" is found, extract the portion of the URL after it
    return baseUrlIndex !== -1 ? originalUrl.substring(baseUrlIndex) : originalUrl;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          {data[0] ? (
            <Breadcrumbs
              title="Simulasi"
              breadcrumbItem={data[0].mfm_ms_nama_simulasi}
            />
          ) : (
            <Breadcrumbs title="Simulasi" breadcrumbItem="Loading ...." />
          )}
          <Row>
            <Card>
              <CardBody>
                <div className="bg-primary bg-soft p-3 rounded">
                  <h3 className="font-size-25 text-primary mb-0">
                    {data && data[0] && data[0].mjs_durasi && (
                      <SessionTimer
                        data={data[0]}
                        editorData={editorData}
                        lastUpdate={lastUpdate}
                        idJadwal={data[0].mjs_id_mjadwal}
                      />
                    )}
                  </h3>
                </div>
              </CardBody>
            </Card>
          </Row>
          <Row>
            <div style={{ height: 700 }}>
              <SplitPane sizes={sizes} onChange={sizes => setSizes(sizes)}>
                <Pane minSize="30%" maxSize="100%">
                  <div style={{ ...layoutCSS, background: "white" }}>
                    <Col className="mx-1">
                      <Card>
                        <CardHeader className="bg-light">
                          <Row>
                            <Col className="col-8">
                              <Nav pills className="navtab-bg nav-justified">
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active: activeTab1 === "5",
                                    })}
                                    onClick={() => {
                                      toggle1("5")
                                    }}
                                  >
                                    Instruksi
                                  </NavLink>
                                </NavItem>
                                <NavItem>
                                  <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                      active: activeTab1 === "6",
                                    })}
                                    onClick={() => {
                                      toggle1("6")
                                    }}
                                  >
                                    Soal
                                  </NavLink>
                                </NavItem>
                                {data &&
                                  data[0] &&
                                  data[0].mjs_id_simulasi_referensi && (
                                    <NavItem>
                                      <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                          active: activeTab1 === "7",
                                        })}
                                        onClick={() => {
                                          toggle1("7")
                                        }}
                                      >
                                        Referensi
                                      </NavLink>
                                    </NavItem>
                                  )}
                              </Nav>
                            </Col>
                            <Col className="d-flex aling-item-start justify-content-end">
                              <button
                                type="button"
                                onClick={() => {
                                  expand()
                                }}
                                className="btn noti-icon "
                              >
                                <i className="bx bx-fullscreen" />
                              </button>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody className="p-0">
                          <TabContent
                            activeTab={activeTab1}
                            className="p-3 text-muted"
                          >
                            <TabPane tabId="5">
                              <Row>
                                <Col lg={activeSidebar}>
                                  <div
                                    style={{
                                      height: defaultHeight,
                                      overflow: "auto",
                                    }}
                                  >
                                    {data &&
                                      data.map(
                                        (item, index) =>
                                          item.mfm_type === "Petunjuk" &&
                                          (item.mfm_mf_dir || item.mfm_file_petunjuk_dir) && (
                                            <ConverterSoal
                                              tipeData="Dokumen"
                                              linkData={
                                                item.mfm_mf_dir ? item.mfm_mf_dir : item.mfm_file_petunjuk_dir
                                              }
                                              key={index}
                                            />
                                          )
                                      )}
                                  </div>
                                </Col>
                              </Row>
                            </TabPane>
                            <TabPane tabId="6">
                              <Row>
                                <Col lg={activeSidebar}>
                                  <div className="text-end">
                                    <button
                                      type="button"
                                      onClick={() => {
                                        Sidebar()
                                      }}
                                      className="btn btn-sm btn-soft-primary mb-2"
                                    >
                                      <i className="bx bx-chevrons-left" />
                                    </button>
                                  </div>
                                  <div className={Visible}>
                                    <Nav pills className="flex-column">
                                      {data &&
                                        data.map(
                                          (item, index) =>
                                            item.mfm_type !== "Petunjuk" && (
                                              <NavItem key={index}>
                                                <NavLink
                                                  style={{ cursor: "pointer" }}
                                                  className={classnames({
                                                    "mb-2": true,
                                                    active:
                                                      verticalActiveTab ===
                                                      index + 1,
                                                  })}
                                                  onClick={() => {
                                                    toggleVertical(index + 1)
                                                  }}
                                                >
                                                  {item.mfm_judul}
                                                </NavLink>
                                              </NavItem>
                                            )
                                        )}
                                    </Nav>
                                  </div>
                                </Col>
                                <Col lg={activeBar}>
                                  <TabContent activeTab={verticalActiveTab} className="text-muted m-0">
                                    {data &&
                                      data.map((item, index) =>
                                        item.mfm_type !== 'Petunjuk' && (
                                          <TabPane key={index} tabId={index + 1}>
                                            <Card>
                                              <div className="example" style={{ height: '100%', width: '100%' }}>
                                                <div
                                                  style={{
                                                    position: 'relative',
                                                    height: '100%',
                                                    width: '100%',
                                                  }}
                                                >
                                                  <CardImg
                                                    src={correctImageUrl(process.env.REACT_APP_FILE_DIGIMAC_URL + `webfile/template/${item.mfm_template_soal}.png`)}
                                                    style={{
                                                      objectFit: 'fill',
                                                    }}
                                                    alt="Background Image"
                                                    className="card-img"
                                                    onError={(e) => {
                                                      console.error(`Error loading background image: ${e.target.src}`, e);
                                                    }}
                                                  />
                                                  <div
                                                    style={{
                                                      position: 'absolute',
                                                      top: '15%',
                                                      left: 0,
                                                      height: '100%',
                                                      width: '100%',
                                                    }}
                                                  >
                                                    <img
                                                      src={correctImageUrl(process.env.REACT_APP_FILE_DIGIMAC_URL + item.mfm_mf_dir)}
                                                      style={{
                                                        objectFit: 'fill',
                                                        height: item.mfm_template_soal === 'berita' ? '100%' : '73%',
                                                        width: '100%',
                                                      }}
                                                      alt="Foreground Image"
                                                      onError={(e) => {
                                                        console.error(`Error loading foreground image: ${e.target.src}`, e);
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </Card>
                                          </TabPane>
                                        )
                                      )}
                                  </TabContent>
                                </Col>
                              </Row>
                            </TabPane>
                            {data &&
                              data[0] &&
                              data[0].mjs_id_simulasi_referensi && (
                                <TabPane tabId="7">
                                  <SoalText
                                    id_referensi={
                                      data[0].mjs_id_simulasi_referensi
                                    }
                                    id_jadwal={data[0].mjs_id_mjadwal}
                                  />
                                </TabPane>
                              )}
                          </TabContent>
                        </CardBody>
                      </Card>
                    </Col>
                  </div>
                </Pane>
                <Pane minSize="30%" maxSize="100%">
                  <div style={{ ...layoutCSS, background: "white" }}>
                    <Col>
                      <Card className="mx-0">
                        <CardHeader className="bg-light">
                          <Row>
                            <Col>
                              <CardTitle className="h4 text-start mb-3">
                                Jawaban
                              </CardTitle>
                            </Col>
                            <Col className="col-5 text-end">
                              <button
                                type="button"
                                onClick={() => {
                                  expandEditor()
                                }}
                                className="btn noti-icon "
                              >
                                <i className="bx bx-fullscreen" />
                              </button>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          {jawabanLoading !== true ? (
                            <Form method="post">
                              <CKEditor
                                editor={ClassicEditor}
                                data={editorData}
                                onReady={editor => {
                                  editor.plugins.get('FileRepository').createUploadAdapter = loader => {
                                    // Configure CKEditor to use your custom upload adapter
                                    return new Base64UploadAdapter(loader);
                                  };
                                }}
                                onChange={handleChange}
                              />
                            </Form>
                          ) : (
                            <div className=" ms-2 mt-2 fw-bold">
                              <div
                                role="status"
                                className="spinner-border text-dark spinner-border-sm"
                              >
                                <span className="visually-hidden">
                                  Loading...
                                </span>
                              </div>{" "}
                              Loading...
                            </div>
                          )}
                        </CardBody>
                      </Card>
                    </Col>
                  </div>
                </Pane>
              </SplitPane>
            </div>
          </Row>
          <Row>
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between">
                  <button
                    type="submit"
                    className="btn btn-soft-primary"
                    onClick={handleSave}
                  >
                    Simpan Jawaban
                  </button>
                  <button
                    type="submit"
                    className="btn btn-soft-success"
                    onClick={handleSubmit}
                  >
                    Selesai
                  </button>
                </div>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default FormEditors
