import React, { useState, useEffect } from "react"
import CountdownTimer from "./CountdownTimerAdmin"

import { Row, Button, Col, Table, CardBody, Card } from "reactstrap"
import Swal from "sweetalert2"
import CountdownTimerAdmin from "./CountdownTimerAdmin"
import { io } from "socket.io-client"

function App(props) {
  const [status, setstatus] = useState(true)
  const [loading, setLoading] = useState(true)
  const [blur, setBlur] = useState(false)
  const [durasi, setdurasi] = useState("")
  const [durasiTimer, setDurasiTimer] = useState("")

  useEffect(() => {
    var myHeaders = new Headers()
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("access_token_2")
    )

    var requestOptionsGet = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    }
    const baseURLGet =
      process.env.REACT_APP_BACKEND_URL_VERSION +
      `jawaban/waktu?id_peserta=` +
      props.id_peserta +
      `&id_simulasi=` +
      props.id_simulasi
    fetch(baseURLGet, requestOptionsGet)
      .then(response => response.json())
      .then(result => {
        if (result.meta.code == 200) {
          const statustimer = result.data[0].status_time
          if (statustimer === false) {
            setstatus(statustimer)
            setDurasiTimer(props.timer / 60)
          } else {
            setDurasiTimer(props.timer / 60)

          }
        }
      })
      .catch(error => {
        console.log("error", error)
      })
  }, [])


  const handleInputChange = event => {
    setdurasi(event.target.value)
  }

  const addDurasi = () => {
    Swal.fire({
      title: "KONFIRMASI",
      text: "Anda yakin untuk menambah durasi ?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          title: "Loading",
          allowOutsideClick: false,
          allowEscapeKey: false,
          allowEnterKey: false,
          showConfirmButton: false,
          didOpen: () => {
            Swal.showLoading()
          },
        }) // Display loading state

        var myHeaders = new Headers()
        myHeaders.append(
          "Authorization",
          "Bearer " + localStorage.getItem("access_token_2")
        )

        var requestOptionsGet = {
          method: "PUT",
          headers: myHeaders,
          redirect: "follow",
        }
        const baseURLGet =
          process.env.REACT_APP_BACKEND_URL_VERSION +
          `tambah-durasi?id_peserta=` +
          props.id_peserta +
          `&id_simulasi=` +
          props.id_simulasi +
          `&durasi=` +
          durasi +
          `&id_jadwal=` +
          props.id_jadwal
        fetch(baseURLGet, requestOptionsGet)
          .then(response => response.json())
          .then(result => {
            Swal.close() // Close loading state
            Swal.fire("Success", "Durasi telah ditambahkan", "success")
            console.log(result)
            window.location.reload()
          })
          .catch(error => {
            console.log("error", error)
          })
      }
    })
  }

  const dateString = props.lastUpdate
  const firstUpdate = props.firstUpdate
  const dateObj = new Date(dateString)
  const dateFirst = new Date(firstUpdate)
  const formattedDate = `${dateObj.getFullYear()}-${(dateObj.getMonth() + 1)
    .toString()
    .padStart(2, "0")}-${dateObj.getDate().toString().padStart(2, "0")}`
  const formattedTime = `${dateObj
    .getHours()
    .toString()
    .padStart(2, "0")}:${dateObj.getMinutes().toString().padStart(2, "0")}`

  const formattedDateTime = `${formattedDate} ${formattedTime}`
  const lastTime = dateObj.toLocaleTimeString()
  const firstTime = dateFirst.toLocaleTimeString()
  const date = dateFirst.toLocaleDateString()

  return (
    <>
      <Row className="mb-1 ms-1">
        {props.status === "selesai" && (
          <Row>
            <Col xl={9}>
              <label>Tambah Waktu</label>
              <div
                style={{ display: "flex", alignItems: "center" }}
                className="text-start"
              >
                <input
                  type="number"
                  min="0"
                  max="999"
                  className="form-control"
                  value={durasi}
                  onChange={handleInputChange}
                  style={{ marginRight: "10px", width: "300px" }}
                />
                <button className="btn btn-primary" onClick={() => addDurasi()}>
                  Tambah
                </button>
              </div>
              <pre>
                <i>tambahkan waktu dalam tipe menit</i>
              </pre>
            </Col>
            <Col xl={3} className="text-end">
              <label style={{ marginRight: "0px" }}></label>
              <Card className="text-end">
                <CardBody>
                  <Table className="table mb-0" style={{ width: "300px" }}>
                    <tbody className="text-start">
                      <tr>
                        <th>Tanggal Pengerjaan</th>
                        <th>:</th>
                        <td>{date}</td>
                      </tr>
                      <tr>
                        <th>Waktu Awal</th>
                        <th>:</th>
                        <td>{firstTime}</td>
                      </tr>
                      <tr>
                        <th>Waktu Akhir</th>
                        <th>:</th>
                        <td>{lastTime}</td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
        {props.status === "proses" && (
          <Col xl={12} className="text-end">
            <p className="font-size-14">
              {props.lastUpdate && <>Last Update : {formattedDateTime}</>}
            </p>
          </Col>
        )}
      </Row>
      <Row>
        <Col xl={12} className="text-center">
          {props.status === "proses" ? (
            durasiTimer != null && status != null ? (
              <CountdownTimerAdmin startTime={durasiTimer} status={status} />
            ) : durasiTimer != null ? (
              <CountdownTimerAdmin startTime={durasiTimer} status={status} />
            ) : (
              <div className="ms-2 mt-2 fw-bold">
                <div
                  role="status"
                  className="spinner-border text-dark spinner-border-sm"
                >
                  <span className="visually-hidden">Loading...</span>
                </div>{" "}
                Loading...
              </div>
            )
          ) : (
            ""
          )}
        </Col>
      </Row>
    </>
  )
}

export default App
